
div.progress-date {
	width: 100px;
	background-color: $brand-color;
	color:#fff;
	padding: 10px;

	h3{
		font-size: 18px;
		margin-top: 5px;
		margin-bottom: 5px; 
	}

	h2{
		font-size: 28px;
		margin: 0;
	}
} 

div.progress-new {
	color: red;
}

h2.progress-title{
	margin-top: 5px;
}


