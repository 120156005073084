
div.view-appointment{

	h3{

		margin-top: 5px;

	}

}


div.setup-appointments,  div.view-appointments{

	.badge {

		background-color: #ff0000;
		
	}
}

span.appts-available {

	color: green;
	font-size: 20px;
	font-weight: bold;

}

.btn-primary-appointment{

	background-color: #f2f2f2;
	border: 1px solid #888;
	display: block;
	border-radius: 0;
	margin: 5px auto;
	padding: 5px 25px; 

}

.btn-primary-appointment:focus, .btn-primary-appointment:hover {
	
	color: #fff;
    background-color: $link-color;
    background-position: 0 -15px;

}

div.my-decor-details p {

	font-size: 17px;

}

