

#flash-message{
	
	position: absolute;
	z-index: 10;
	bottom: 20px;
	right: 20px;

	span{
		color: #93140d;
		font-weight: bold;
		font-size: 18px;
	}
	
  
}


