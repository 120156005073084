
div.referral {
	img.referralthumb {
		margin-top: 20px;
	}
}


p.referral-small-text {
	font-size: 11px;
}