


#home-buying-process-id {

		.div-width1000 {
		  display: block;
		  width: 992px;
		  height: auto;
		  margin-right: auto;
		  margin-left: auto;
		}

		.left-image {
		  display: inline-block;
		  width: 100px;
		  height: 100px;
		  margin-top: 124px;
		  float: left;
		}

		.left-image.next {
		  margin-top: 60px;
		}

		.middle-with-number {
		  display: inline-block;
		  width: 340px;
		  margin-top: 129px;
		  float: left;
		}

		.middle-with-number.next2 {
		  margin-top: 60px;
		}

		.rightside2divs {
		  display: inline-block;
		  width: 550px;
		  margin-top: 33px;
		  background-color: #58595b;
		}

		.rightsidebottom {
		  padding: 15px;
		}

		.image {
		  padding: 5px;
		}

		.maintext {
		  padding-top: 10px;
		  font-family: 'Open Sans', sans-serif;
		  color: #fff;
		  font-size: 16px;
		  line-height: 24px;
		}

		.text-block {
		  font-family: 'Open Sans', sans-serif;
		  color: #fff;
		  font-size: 24px;
		  font-weight: 700;
		}

		.roundnumbberdiv {
		  display: inline-block;
		  width: 84px;
		  height: 84px;
		  margin-left: 97px;
		  border: 3px solid #fff;
		  border-radius: 42px;
		  background-color: #58595b;
		}

		.roundtextactualnumber {
		  padding-top: 6px;
		  font-family: 'Open Sans', sans-serif;
		  color: #fff;
		  font-size: 45px;
		  text-align: center;
		}

		.toprow {
		  background-image: url('/images/home-buying-process/top-cross.png');
		  background-position: 0px 0px;
		  background-size: cover;
		}

		.toprow.next3 {
		  background-position: 0px -60px;
		}

		.toprow.secondbigimage {
		  background-position: -29px -8px;
		}

		.toprow.firstrow {
		  background-image: url('/images/home-buying-process/top-cross2.png');
		  background-position: -18px 0px;
		  background-size: cover;
		}

		.titlebox {
		  width: 410px;
		  margin-top: 5px;
		  margin-bottom: 5px;
		  padding-top: 22px;
		  padding-bottom: 22px;
		  border: 4px solid #58595b;
		  text-align: center;
		}

		.titletextinbox {
		  font-family: 'Open Sans', sans-serif;
		  color: #58595b;
		  font-size: 26px;
		  font-weight: 700;
		}

		.titlespacerdiv {
		  display: none;
		}

		.rightsidemagrinbottom {
		  height: 33px;
		  background-color: #fff;
		}

		.list-item {
		  padding-top: 5px;
		  font-family: 'Open Sans', sans-serif;
		  color: #fff;
		  font-size: 16px;
		}

		.unordered-list {
		  padding-top: 16px;
		}

		.link {
		  color: #fff;
		  font-weight: 600;
		}

		


}


@media (max-width: 991px) {
	#home-buying-process-id {
		  .div-width1000 {
			width: 95%;
			padding-right: 15px;
			padding-left: 15px;
			background-image: none;
		  }
		  .left-image {
			display: none;
		  }
		  .middle-with-number {
			width: 37%;
			background-image: url('/images/home-buying-process/line-rightsidenumber.png');
			background-position: 50% 50%;
			background-size: cover;
			text-align: center;
		  }
		  .rightside2divs {
			width: 60%;
			margin-top: auto;
		  }
		  .rightsidebottom {
			padding-top: 15px;
		  }
		  .roundnumbberdiv {
			margin-left: auto;
		  }
		  .toprow {
			background-image: none;
		  }
		  .toprow.next3 {
			background-image: none;
		  }
		  .toprow.firstrow {
			background-image: none;
		  }
		  .titlerow {
			width: 100%;
			text-align: left;
		  }
		  .titlebox {
			display: block;
			width: 54%;
			margin-right: 6%;
			margin-left: 3%;
			padding-right: 15px;
			padding-left: 15px;
			float: right;
		  }
		  .titletextinbox {
			line-height: 28px;
		  }
		  .titlespacerdiv {
			display: inline-block;
			width: 37%;
			float: left;
		  }
		  .rightsidemagrinbottom {
			background-image: url('/images/home-buying-process/line-rightsideverticle.png');
			background-position: 50% 50%;
			background-size: 9px;
			background-repeat: repeat-y;
		  }
	}
}


@media (max-width: 767px) {
	#home-buying-process-id {
		  .titlebox {
			width: 54%;
		  }
		  .titletextinbox {
			line-height: 28px;
		  }
	}
}

@media (max-width: 479px) {
	#home-buying-process-id {
	  .maintext {
		font-size: 13px;
		line-height: 18px;
	  }
	  .text-block {
		font-size: 20px;
	  }
	  .titlebox.bigger {
		width: auto;
	  }
	  .list-item {
		font-size: 12px;
		line-height: 14px;
	  }
	  .unordered-list {
		padding-left: 15px;
	  }
	}
}

.w-clearfix:before,
.w-clearfix:after {
  content: " ";
  display: table;
}
.w-clearfix:after {
  clear: both;
}