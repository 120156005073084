

div.lot-number {
  background-color: #939598;
  border:4px solid white;
  color: #fff;
  padding-bottom: 5px;

  h2 {
    padding-bottom:6px;

  }

}


div.lot-button {
   padding:8px;
}

div.lot-sold {
	background-color: #ff0000;
}